body {
  margin: 0;
  padding: 0;
}

.container {
  max-width: 1170px;
}

img {
  max-width: 100%;
}

.header__navigation_link,
.header__description,
.about__description,
.start__description,
.tariffs__description,
.tariffs__period,
.contact__submit,
.contact_attention,
.footer__information {
  font-family: Open Sans, sans-serif;
  font-size: 14px;
}

.header__title,
.header__btn,
.about__title,
.features__promo,
.tariffs__block_name,
.tariffs__price,
.modal__text,
.partners__title {
  font-family: Open Sans, sans-serif;
  font-weight: bold;
  font-size: 20px;
}

.header {
  padding-top: 5%;
  padding-bottom: 10%;
  background-image: url("../img/header-bg.jpg");
  background-size: cover;
  background-position: center;
}

.header .container {
  position: static;
}

.header .col-12.col-md-9 {
  position: static;
}

.header__logo_img {
  display: inline-block;
}

.header__logo_text {
  display: inline-block;
  color: #fff;
}

.header__navigation {
  display: flex;
  justify-content: space-between;
}

.header__navigation_item {
  list-style-type: none;
}

.header__navigation_link {
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  text-align: center;
  display: inline-block;
  box-sizing: border-box;
  cursor: pointer;
}

.header__navigation_link-main {
  width: 63px;
}

.header__navigation_link-about {
  width: 63px;
}

.header__navigation_link-features {
  width: 128px;
}

.header__navigation_link-start {
  width: 120px;
}

.header__navigation_link-price {
  width: 80px;
}

.header__navigation_link-contact {
  width: 150px;
}

.header__navigation_link:hover {
  text-decoration: none;
  color: #f9c320;
}

.header__navigation_link:focus {
  text-decoration: none;
  color: #fff;
  outline: 0;
}

.header__navigation_link:active {
  outline: none;
  text-decoration: none;
}

.header__navigation_link:visited {
  outline: none;
  text-decoration: none;
  color: #fff;
}

.header__navigation.nav__fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 2%;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.7);
}

.header__title {
  color: #fff;
  font-size: 42px;
  margin-top: 20%;
  margin-bottom: 2%;
  animation-duration: 2s;
}

.header__description {
  margin-bottom: 2%;
  color: #fff;
  font-size: 18px;
  animation-duration: 2s;
  animation-delay: 2.2s;
}

.header__btn {
  display: block;
  margin: 0 auto;
  text-align: center;
  color: #000000;
  width: 220px;
  height: 48px;
  box-sizing: border-box;
  text-decoration: none;
  border-radius: 5px;
  animation-delay: 8s;
  font-size: 16px;
  line-height: 48px;
  background-color: #f9c320;
}

.header__btn:hover {
  text-decoration: none;
  color: #000000;
}

.header__btn:focus {
  text-decoration: none;
  color: #000000;
}

.header__btn:active {
  text-decoration: none;
  color: #000000;
}

.header__hide-nav {
  position: absolute;
  top: 28px;
  right: 22px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: none;
}

.header__hide-nav_line {
  position: absolute;
  display: block;
  width: 80%;
  left: 10%;
  height: 3px;
  background-color: #fff;
  border-radius: 5px;
}

.header__hide-nav_line-top {
  top: 8px;
}

.header__hide-nav_line-middle {
  top: 18px;
  opacity: 1;
}

.header__hide-nav_line-bottom {
  top: 28px;
}

.header__hide-nav.active .header__hide-nav_line-top {
  top: 20px;
  transform: rotate(45deg);
  transition: 0.4s;
}

.header__hide-nav.active .header__hide-nav_line-middle {
  opacity: 0;
}

.header__hide-nav.active .header__hide-nav_line-bottom {
  top: 20px;
  transform: rotate(-45deg);
  transition: 0.4s;
}

.header__hide-nav.active .header__navigation {
  display: block;
  position: fixed;
  width: 300px;
  right: 0;
  z-index: 100;
}

.about .container {
  position: relative;
}

.about__title {
  margin-top: 7.2%;
  position: relative;
  color: #000000;
  font-size: 32px;
  animation-duration: 2s;
}

.about__description {
  position: relative;
  font-size: 20px;
  margin-bottom: 12%;
  margin-top: 8%;
  animation-delay: 4s;
  animation-duration: 2s;
}

.about__description_service::before,
.about__description_base::before {
  display: block;
  position: absolute;
  width: 140px;
  height: 140px;
  content: "";
  top: -20px;
  left: -150px;
  background: url("../img/car__service.svg") no-repeat center;
}

.about__description_base::before {
  background: url("../img/computer_1.svg") no-repeat center;
}

.features__promo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  color: #fff;
  font-size: 22px;
}

.features .js-slider {
  max-width: 1908px;
}

.start {
  background-color: #f3f3f3;
  /*.container
		.row:nth-child(2)
			animation-delay: 0.5s
		.row:nth-child(3)
			animation-delay: 0.5s
		.row:nth-child(4)
			animation-delay: 0.5s
		.row:nth-child(5)
			animation-delay: 0.5s */
}

.start__title {
  animation-delay: 0.2s;
}

.start__description {
  margin-top: 3%;
  margin-bottom: 10%;
  font-size: 22px;
  line-height: 28px;
}

.start__description::before {
  display: block;
  position: absolute;
  width: 140px;
  height: 140px;
  content: "";
  top: -15px;
  left: -150px;
  background: url("../img/icon__laptop.svg") no-repeat center;
}

.start__description_choose::before {
  animation-delay: 4s;
  background: url("../img/icon__passed.svg") no-repeat center;
}

.start__description_pay::before {
  animation-delay: 6s;
  background: url("../img/icon__cash.svg") no-repeat center;
}

.start__description_work::before {
  animation-delay: 8s;
  background: url("../img/icon__system.svg") no-repeat center;
}

.start__registration {
  margin-top: 2%;
  margin-bottom: 8.82%;
  animation-delay: 10s;
}

.tariffs {
  padding-bottom: 12%;
  background-image: url("../img/price-bg.jpg");
  background-repeat: no-repeat;
  background-position: 70% 50%;
  background-size: cover;
}

.tariffs__title {
  color: #fff;
  padding-top: 4%;
  padding-bottom: 5%;
  font-size: 32px;
}

.tariffs__block {
  margin-top: 55px;
  border: 2px solid #f9c320;
  border-radius: 2px;
  padding: 0;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.tariffs__block_name {
  color: rgba(220, 76, 76, 0.8);
  text-decoration: underline;
  font-size: 32px;
  width: 100%;
  padding: 20px 0;
}

.tariffs__description {
  padding-top: 50px;
  padding-bottom: 41px;
  width: 80%;
  white-space: normal;
  font-size: 20px;
  word-wrap: break-word;
}

.tariffs__period {
  width: 80%;
  white-space: normal;
  font-size: 20px;
  word-wrap: break-word;
}

.tariffs__price {
  font-size: 32px;
  color: #fff;
  background-color: rgba(220, 76, 76, 0.8);
  width: 100%;
  padding: 20px 0;
  margin: 0;
  text-decoration: none;
}

.tariffs__price:focus {
  text-decoration: none;
  color: #fff;
}

.tariffs__price:hover {
  text-decoration: none;
  color: #fff;
}

.contact {
  padding-bottom: 8%;
}

.contact__title {
  margin-bottom: 4%;
}

.contact__field_name,
.contact__field_email {
  display: block;
  width: 100%;
  border: 2px solid grey;
  margin-top: 15px;
}

.contact__field_name:focus,
.contact__field_name:hover,
.contact__field_name:active,
.contact__field_email:focus,
.contact__field_email:hover,
.contact__field_email:active {
  border-color: #f9c320;
  outline: 0;
}

.contact__message {
  display: block;
  margin: 10% 0;
  width: 100%;
  border: 2px solid grey;
}

.contact__message:focus,
.contact__message:hover,
.contact__message:active,
.contact__message:visited {
  border-color: #f9c320;
  outline: 0;
}

.contact__submit {
  display: inline-block;
  width: 100%;
  height: 50px;
  background-color: transparent;
  cursor: pointer;
  border: 2px solid grey;
}

.contact__submit:focus,
.contact__submit:hover,
.contact__submit:active,
.contact__submit:visited {
  border-color: #f9c320;
  outline: 0;
}

.contact_attention {
  color: #ea1c1c;
  font-size: 12px;
}

.footer {
  background-color: #000000;
  padding: 1%;
}

.footer__information {
  color: #fff;
  font-size: 12px;
  margin-top: 1%;
}

.error__input {
  border-color: #ea1c1c;
}

.overlay {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 900;
}

.modal {
  width: 300px;
  height: 200px;
  padding: 20px;
  text-align: center;
  background-color: #fff;
  position: fixed;
  top: 100px;
  left: 50%;
  z-index: 1000;
  display: none;
}

.modal .btn_red {
  width: 220px;
  padding: 16px;
  display: inline-block;
  color: #fff;
  background-color: #ea1c1c;
  border-radius: 0;
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.1;
  border: 0;
  text-decoration: none;
  text-transform: uppercase;
}

.modal__text {
  font-size: 14px;
  margin-bottom: 35px;
  margin-top: 10px;
}

.open-modal {
  overflow-y: hidden;
}

.tariffs__condition {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: space-between;
}

.partners__title {
  margin-top: 7.2%;
  margin-bottom: 30px;
  position: relative;
  color: #000000;
  font-size: 32px;
  animation-duration: 2s;
  text-transform: uppercase;
  text-align: center;
}

.partners__item {
  text-align: center;
}

.partners__link {
  display: inline-block;
}

@media screen and (max-width: 1100px) {
  .header__navigation_item {
    display: block;
    margin-top: 4%;
    text-align: center;
    border-bottom: 1px dotted #fff;
  }

  .header__hide-nav {
    display: block;
  }

  .header__navigation {
    display: none;
    position: absolute;
    right: 0;
    width: 100%;
    padding: 5%;
    background-color: #000000;
    z-index: 10;
  }
}

@media screen and (max-width: 1000px) {
  .start__description_pay::before {
    top: -45px;
  }

  .contact__field_name,
  .contact__field_email {
    margin-top: 5%;
  }
}

@media screen and (max-width: 768px) {
  .header__title {
    font-size: 30px;
  }

  .header__description {
    font-size: 16px;
  }

  .about__title {
    font-size: 26px;
  }

  .about__description {
    text-align: left;
  }

  .about__description_service::before,
  .about__description_base::before {
    top: -25px;
    left: -133px;
    background-size: 65%;
  }

  .features__promo {
    font-size: 16px;
  }

  .start__description {
    font-size: 18px;
    margin-bottom: 15%;
  }

  .start__description::before {
    background-size: 65%;
    left: -127px;
  }

  .start__description_pay::before {
    top: -30px;
  }

  .tariffs__block {
    margin-top: 55px;
  }

  .contact__field_name,
  .contact__field_email {
    margin-bottom: 5%;
  }

  .partners__title {
    font-size: 26px;
  }
}

@media screen and (max-width: 576px) {
  .header__title {
    font-size: 22px;
  }

  .header__description {
    font-size: 14px;
  }

  .about__title {
    font-size: 20px;
  }

  .about__description {
    font-size: 16px;
    margin-bottom: 20%;
  }

  .about__description_service::before,
  .about__description_base::before {
    top: -42px;
    left: -101px;
    background-size: 35%;
  }

  .features__promo {
    font-size: 14px;
  }

  .start__description {
    font-size: 14px;
    margin-bottom: 15%;
  }

  .start__description::before {
    left: -90px;
    background-size: 35%;
  }

  .start__description_choose::before {
    left: -90px;
    top: -28px;
  }

  .tariffs__block {
    margin-top: 30px;
  }

  .tariffs__block_name {
    font-size: 20px;
    padding-top: 30px;
  }

  .tariffs__description {
    padding-top: 30px;
    padding-bottom: 20px;
    font-size: 16px;
    text-align: left;
  }

  .tariffs__price {
    font-size: 26px;
    padding-bottom: 26px;
  }

  .contact__field_name,
  .contact__field_email {
    margin-top: 4%;
    margin-bottom: 8%;
  }

  .partners__title {
    font-size: 20px;
  }
}

@media screen and (max-width: 280px) {
  .features__promo {
    font-size: 12px;
  }

  .start__description_pay::before {
    left: -84px;
  }

  .start__description_work::before {
    left: -84px;
  }
}